import { GraphQLApi, GraphQLResponse } from '@maverick/http';
import { SitecoreItemPath } from '../../constants/Sitecore';
import { Config } from '../../../Config';

const SitecoreClient = new GraphQLApi(Config.SitecoreGraphQLEndpoint, {
	sc_apikey: Config.SitecoreApiKey,
});

export const SitecoreService = {
	HomeHeroHeadline: async <T>(): Promise<GraphQLResponse<T>> => {
		const query = `
			query GetHowItWorkTitle($datasource: String!, $language: String!) {
				item(path: $datasource, language: $language) {
					... on _HeadlineHomepage {
						title {
							value
						}
						subtitle {
							value
						}
					}
				}
			}
		`;

		return await SitecoreClient.ExecuteRequest<T>(query, {
			datasource: SitecoreItemPath.Home.HeadlineHomepageId,
			language: 'en',
		});
	},
};
