export const SitecoreItemPath = {
	Shared: {
		HowItWorksBannerDataSourceId: '{C73CC2DF-699B-4C18-9938-E9E3DA047B46}',
		FrequentlyAskedQuestionsDataSourceId: '{C73C9A94-1E38-4947-B2F8-E16486945713}',
	},
	Dashboard: {
		Root: '{A190AA59-9D39-4CC8-812D-FBB30E2F9E19}',
	},
	Home: {
		HeadlineHomepageId: '{E2A8F741-9DA6-480D-9451-491FC415E0D2}',
	},
};
