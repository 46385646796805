const env = String(process?.env?.ENV);

export const Config = {
	Env: {
		Is: env,
		IsLocal: env === 'local',
		IsDev: env === 'dev',
		IsQa: env === 'qa',
		IsStg: env === 'stg',
		IsPreview: env === 'preview',
		IsPrd: env === 'prd',
	},
	SitecoreApiKey: String(process?.env?.SITECORE_API_KEY),
	SitecoreGraphQLEndpoint: String(process?.env?.GRAPH_QL_ENDPOINT),
	SitecoreApiHostEndpoint: String(process?.env?.SITECORE_API_HOST),
} as const;
