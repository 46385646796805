import { GraphQLApi, GraphQLResponse } from '@maverick/http';
import { SitecoreItemPath } from '../../constants/Sitecore';
import { Config } from '../../../Config';

const SitecoreClient = new GraphQLApi(Config.SitecoreGraphQLEndpoint, {
	sc_apikey: Config.SitecoreApiKey,
});

export const SitecoreService = {
	GetHowItWorkBanner: async <T>(): Promise<GraphQLResponse<T>> => {
		const query = `
			query GetHowItWorkBanner($datasource: String!, $language: String!) {
				item(path: $datasource, language: $language) {
					... on _SplitBanner {
						title {
							value
						}
						subtitle {
							value
						}
						description {
							value
						}
						message {
							value
						}
						image {
							alt
							src
							width
							height
						}
						button {
							text
							url
							target
						}
					}
				}
			}
		`;

		return await SitecoreClient.ExecuteRequest<T>(query, {
			datasource: SitecoreItemPath.Shared.HowItWorksBannerDataSourceId,
			language: 'en',
		});
	},
	GetFrequentlyAskedQuestions: async <T>(): Promise<GraphQLResponse<T>> => {
		const query = `
			query GetFrequentlyAskedQuestions($datasource: String!, $language: String!) {
				item(path: $datasource, language: $language) {
					... on _AccordionList {
						title {
							value
						}
						description {
							value
						}
						accordionItem {
							targetItems {
								title: field(name: "title") {
									value
								}
								description: field(name: "description") {
									value
								}
							}
						}
						columns {
							value
						}
					}
				}
			}
		`;

		return await SitecoreClient.ExecuteRequest<T>(query, {
			datasource: SitecoreItemPath.Shared.FrequentlyAskedQuestionsDataSourceId,
			language: 'en',
		});
	},
};
